import LoadingLine from "components/LoadingLine";
import { IsLoading, TableCellContent, TableCellProps } from "../types";
import styles from "../Table.module.css";

const TableCell = (props: TableCellProps) => {
  const { content, colIndex } = props;
  const contentToShow = getContentToShow(content);
  return (
    <td className={getStyles(colIndex)}>
      {/*for aligning loading bars*/}
      <div className={getAlignmentClassName(colIndex)}>
        <div className={styles.cellContents}>{contentToShow}</div>
      </div>
    </td>
  );
};

function getStyles(colIndex: number) {
  const styleClasses = [styles.cell];
  if (colIndex === 0) {
    styleClasses.push(styles.firstCol);
  }
  return styleClasses.join(" ");
}

function getAlignmentClassName(colIndex: number) {
  if (colIndex === 0) {
    return styles.firstCol;
  }
  return styles.centered;
}

function getContentToShow(content: TableCellContent) {
  if (content === IsLoading.true) {
    return <LoadingCellContent />;
  }
  if (typeof content === "string") {
    <LimitedLengthCellContent>{content}</LimitedLengthCellContent>;
  }
  return content;
}

export const LimitedLengthCellContent = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className={styles.cellContentsString}>{children}</div>;
};

export const LoadingCellContent = () => {
  return (
    <div className={styles.loadingContainer}>
      <LoadingLine style={{ width: "100%" }} />
    </div>
  );
};

export default TableCell;
