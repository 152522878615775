import styles from "./SideMenu.module.css";
import {
  GiftCardMajor,
  HomeMajor,
  QuestionMarkMajor,
  BlogMajor,
} from "@shopify/polaris-icons";
import { useRouter } from "next/router";
import MenuItem from "./MenuItem";
import { routes } from "pages/_app";
import { MouseEventHandler } from "react";
import { useIntercom } from "react-use-intercom";

const SideMenuWithController = () => {
  const { route, push } = useRouter();
  const dashboardSelected = route === routes.dashboard;
  const offersSelected = route === routes.offers;
  const settingsSelected = route === routes.settings;
  const { showNewMessages } = useIntercom();

  const onDashboardClick = () => push(routes.dashboard);
  const onOffersClick = () => push(routes.offers);
  const onSettingsClick = () => push(routes.settings);

  return SideMenu({
    dashboard: {
      isSelected: dashboardSelected,
      onClick: onDashboardClick,
    },
    offers: {
      isSelected: offersSelected,
      onClick: onOffersClick,
    },
    settings: {
      isSelected: settingsSelected,
      onClick: onSettingsClick,
    },
    help: {
      isSelected: false,
      onClick: () => showNewMessages("Hi, I'm looking for some help!"),
    },
  });
};

const SideMenu = ({ dashboard, offers, help, settings }: SideMenuProps) => {
  return (
    <div className={styles.sideMenu}>
      <div className={styles.menuContents}>
        <MenuItem
          title="Dashboard"
          icon={HomeMajor}
          isSelected={dashboard.isSelected}
          onClick={dashboard.onClick}
        />
        <MenuItem
          title="Upsell Offers"
          icon={GiftCardMajor}
          isSelected={offers.isSelected}
          onClick={offers.onClick}
        />
        <MenuItem
          title="Translations"
          icon={BlogMajor}
          isSelected={settings.isSelected}
          onClick={settings.onClick}
        />
        <MenuItem
          title="Need Help?"
          icon={QuestionMarkMajor}
          isSelected={help.isSelected}
          onClick={help.onClick}
        />
      </div>
    </div>
  );
};

export interface SideMenuProps {
  dashboard: MenuItemData;
  offers: MenuItemData;
  settings: MenuItemData;
  help: MenuItemData;
}

interface MenuItemData {
  isSelected: boolean;
  onClick: MouseEventHandler;
}

export { SideMenu };
export default SideMenuWithController;
