import { useEffect, useState } from "react";

export function useFaviconGrabber(shopDomain?: string) {
  const [faviconSrc, setFaviconSrc] = useState<string | null>(faviconCache.get);

  useEffect(() => {
    faviconCache.set(faviconSrc);
  }, [faviconSrc]);

  useEffect(() => {
    if (!shopDomain) return;
    let cancelled = false;
    getFavicon({ domain: shopDomain }).then((newFaviconSrc) => {
      if (cancelled) return;
      setFaviconSrc(newFaviconSrc);
    });
    return () => {
      cancelled = true;
    };
  }, [shopDomain]);

  return faviconSrc;
}

const faviconStorageKey = "shop_favicon";

const faviconCache = {
  get: () => {
    const storedFaviconSrc = localStorage.getItem(faviconStorageKey);
    if (storedFaviconSrc) {
      return storedFaviconSrc;
    }
    return null;
  },
  set: (newFaviconSrc: string | null) => {
    if (newFaviconSrc === null) {
      localStorage.removeItem(faviconStorageKey);
    } else {
      localStorage.setItem(faviconStorageKey, newFaviconSrc);
    }
  },
};

async function getFavicon({
  domain,
}: {
  domain: string;
}): Promise<string | null> {
  return fetch(`https://favicongrabber.com/api/grab/${domain}`)
    .then((res) => res.json())
    .then((data) => {
      const { icons } = data;
      if (!icons) {
        return null;
      }
      const img = icons[0]?.src;
      if (!img) {
        return null;
      }
      return img;
    })
    .catch((e) => {
      console.error(e);
      return null;
    });
}
