import LoadingLine from "components/LoadingLine";
import { useRouter } from "next/router";
import { routes } from "pages/_app";
import styles from "./Header.module.scss";
import { ProfilePhotoOrInitials } from "./ProfilePhoto";

export interface HeaderProps {
  name: string | null;
  storeUrl: string | null;
  hasError: boolean;
}

const Header = (props: HeaderProps) => {
  const router = useRouter();
  return (
    <div className={styles.header}>
      <div
        className={[styles.brandContainer, styles.clickable].join(" ")}
        onClick={() => router.push(routes.dashboard)}
      >
        <img src="/logo_fullname.png" className={styles.brandLogo} />
      </div>
      <ProfileContainer {...props} />
    </div>
  );
};

const ProfileContainer = ({
  name,
  storeUrl,
  hasError,
}: ProfileContainerProps) => {
  if (hasError) {
    return null;
  }

  return (
    <div style={{ position: "relative" }}>
      <div className={styles.profileContainer}>
        <ProfilePhotoOrInitials
          shopOwnerName={name ? name : undefined}
          shopDomain={storeUrl ? storeUrl : undefined}
        />
        <div className={styles.profileTextContainer}>
          {name ? (
            <span className={styles.profileName}>{name}</span>
          ) : (
            <ProfileLoadingLine />
          )}
          {storeUrl ? (
            <span className={styles.profileStoreUrl}>{storeUrl}</span>
          ) : (
            <ProfileLoadingLine />
          )}
        </div>
      </div>
    </div>
  );
};

type ProfileContainerProps = HeaderProps;

const ProfileLoadingLine = () => {
  return <LoadingLine style={{ width: "70px" }} />;
};

export default Header;
