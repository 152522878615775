import { useFaviconGrabber } from "./FaviconGrabber";
import styles from "./Header.module.scss";

export const ProfilePhotoOrInitials = (props: ProfilePhotoOrInitialsProps) => {
  const faviconSrc = useFaviconGrabber(props.shopDomain);

  return (
    <div className={styles.profilePhotoContainer}>
      {faviconSrc ? (
        <img className={styles.profilePhoto} src={faviconSrc} />
      ) : (
        <div className={styles.initials}>
          {getInitialsFromName(props.shopOwnerName)}
        </div>
      )}
    </div>
  );
};

function getInitialsFromName(name?: string): string {
  if (name === undefined) return "";
  const parts = name.trim().split(" ");
  if (parts.length === 1) {
    const part = parts[0];
    if (part === undefined) {
      return "";
    }
    return part.slice(0, 2);
  }
  const firstPart = parts[0];
  const lastPart = parts[parts.length - 1];
  if (firstPart === undefined || lastPart === undefined) {
    return "";
  }
  const firstInitial = firstPart[0];
  const lastInitial = lastPart[0];
  if (!firstInitial || !lastInitial) {
    return "";
  }
  return firstInitial + lastInitial;
}

interface ProfilePhotoOrInitialsProps {
  shopOwnerName?: string;
  shopDomain?: string;
}
