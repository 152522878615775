import { DateRangeSelections } from "./dropdown";

export function getPreviousMonthAndYear(
  date: Date
): { month: number; year: number } {
  const givenMonth = date.getMonth();
  if (givenMonth <= 1) {
    return {
      month: 12,
      year: date.getFullYear() - 1,
    };
  } else {
    return {
      month: date.getMonth() - 1,
      year: date.getFullYear(),
    };
  }
}

export function toNiceDateString(date: Date): string {
  const dateTimeFormat = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return dateTimeFormat.format(date);
}

export function dateStringFromAny(obj: string | number | Date | undefined) {
  if (obj instanceof Date) {
    return toNiceDateString(new Date(obj.getTime() + 100));
  }
  return "";
}

const SECS_PER_DAY = 864e5;

export function getDateRangeFromSelection(
  selection: string
): { start: Date; end: Date } | null {
  switch (selection) {
    case DateRangeSelections.today:
      return getToday();
    case DateRangeSelections.yesterday:
      return getYesterday();
    case DateRangeSelections.last7days:
      return getLast7days();
    case DateRangeSelections.last30days:
      return getLast30days();
    case DateRangeSelections.prevMonth:
      return getPreviousMonth();
    case DateRangeSelections.prevYear:
      return getPreviousYear();
    case DateRangeSelections.weekToDate:
      return getWeekToDate();
    case DateRangeSelections.monthToDate:
      return getMonthToDate();
    case DateRangeSelections.yearToDate:
      return getYearToDate();
    case DateRangeSelections.quarter4lastYear:
      return get4thQuarterOfLastYear();
    case DateRangeSelections.quarter3lastYear:
      return get3rdQuarterOfLastYear();
    case DateRangeSelections.quarter2lastYear:
      return get2ndQuarterOfLastYear();
    case DateRangeSelections.quarter1lastYear:
      return get1stQuarterOfLastYear();
    default:
      return null;
  }
}

function getToday() {
  return { start: new Date(), end: new Date() };
}

function getYesterday() {
  return {
    start: new Date(Date.now() - SECS_PER_DAY),
    end: new Date(Date.now() - SECS_PER_DAY),
  };
}

function getLast7days() {
  return {
    start: new Date(Date.now() - SECS_PER_DAY * 7),
    end: new Date(),
  };
}

function getLast30days() {
  return {
    start: new Date(Date.now() - SECS_PER_DAY * 30),
    end: new Date(Date.now()),
  };
}

function getPreviousMonth() {
  const now = new Date();
  const currYear = now.getFullYear();
  const currMonth = now.getMonth();
  var lastMondayLastDay = new Date();
  lastMondayLastDay.setDate(1);
  lastMondayLastDay.setHours(-1);

  return {
    start: new Date(currYear, currMonth - 1, 1),
    end: lastMondayLastDay,
  };
}

function getPreviousYear() {
  const now = new Date();
  const currYear = now.getFullYear();
  return {
    start: new Date(currYear - 1, 0, 1),
    end: new Date(currYear - 1, 11, 31),
  };
}

function getWeekToDate() {
  var lastSunday = new Date();
  lastSunday.setDate(lastSunday.getDate() - lastSunday.getDay());

  return {
    start: lastSunday,
    end: new Date(),
  };
}

function getMonthToDate() {
  const now = new Date();
  const currYear = now.getFullYear();
  const currMonth = now.getMonth();
  return {
    start: new Date(currYear, currMonth, 1),
    end: new Date(Date.now()),
  };
}

function getYearToDate() {
  const now = new Date();
  const currYear = now.getFullYear();
  return {
    start: new Date(currYear, 0, 1),
    end: new Date(Date.now()),
  };
}

function get4thQuarterOfLastYear() {
  const now = new Date();
  const currYear = now.getFullYear();
  return {
    start: new Date(currYear - 1, 9, 1),
    end: new Date(currYear - 1, 11, 31),
  };
}

function get3rdQuarterOfLastYear() {
  const now = new Date();
  const currYear = now.getFullYear();
  return {
    start: new Date(currYear - 1, 6, 1),
    end: new Date(currYear - 1, 8, 30),
  };
}

function get2ndQuarterOfLastYear() {
  const now = new Date();
  const currYear = now.getFullYear();
  return {
    start: new Date(currYear - 1, 3, 1),
    end: new Date(currYear - 1, 5, 30),
  };
}

function get1stQuarterOfLastYear() {
  const now = new Date();
  const currYear = now.getFullYear();
  return {
    start: new Date(currYear - 1, 0, 1),
    end: new Date(currYear - 1, 2, 31),
  };
}
