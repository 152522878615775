import styles from "../../Table.module.css";

export const SortingIconBothDescending = () => {
  return (
    <svg
      className={styles.sortingIcon}
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.55203 12L0.931152 8H10.1729L5.55203 12Z"
        fill="#5C6AC4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.55203 0L10.1729 4H0.931152L5.55203 0Z"
        fill="#212B36"
        fillOpacity="0.25"
      />
    </svg>
  );
};

export const SortingIconBothInactive = () => {
  return (
    <svg
      className={styles.sortingIcon}
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.55203 12L0.931152 8H10.1729L5.55203 12Z"
        fill="#212B36"
        fillOpacity="0.25"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.55203 0L10.1729 4H0.931152L5.55203 0Z"
        fill="#212B36"
        fillOpacity="0.25"
      />
    </svg>
  );
};

export const SortingIconBothAscending = () => {
  return (
    <svg
      className={styles.sortingIcon}
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.55203 12L0.931152 8H10.1729L5.55203 12Z"
        fill="#212B36"
        fillOpacity="0.25"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.55203 0L10.1729 4H0.931152L5.55203 0Z"
        fill="#5C6AC4"
      />
    </svg>
  );
};

export const SortingIconOnlyUpAscending = () => {
  return (
    <svg
      className={[styles.sortingIcon, styles.onlyUpIcon].join(" ")}
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.55203 0L10.1729 4H0.931152L5.55203 0Z"
        fill="#5C6AC4"
      />
    </svg>
  );
};

export const SortingIconOnlyUpInactive = () => {
  return (
    <svg
      className={[styles.sortingIcon, styles.onlyUpIcon].join(" ")}
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.55203 0L10.1729 4H0.931152L5.55203 0Z"
        fill="#212B36"
        fillOpacity="0.25"
      />
    </svg>
  );
};

export const SortingIconOnlyDownInactive = () => {
  return (
    <svg
      className={[styles.sortingIcon, styles.onlyDownIcon].join(" ")}
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.55203 12L0.931152 8H10.1729L5.55203 12Z"
        fill="#212B36"
        fillOpacity="0.25"
      />
    </svg>
  );
};

export const SortingIconOnlyDownDescending = () => {
  return (
    <svg
      className={[styles.sortingIcon, styles.onlyDownIcon].join(" ")}
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.55203 12L0.931152 8H10.1729L5.55203 12Z"
        fill="#5C6AC4"
      />
    </svg>
  );
};
