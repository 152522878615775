import { useEffect, useRef, useState } from "react";
import StaticTable from "./StaticTable";
import {
  SortableTableProps,
  SortDirection,
  SortableHeaderItemData,
  AllowedSortDirections,
  InitialSortDetails,
  TableBodyData,
  TableCellContent,
  IsLoading,
} from "./types";
import { SortableHeaderItem, StaticHeaderItem } from "./components/HeaderItem";

/*  Only allow one header item to have sorting set to ascending/descending (active). Activating sort
    on a non-active header item will remove the sort from the current/previously
    active one. Header items can only sort in a direction if the onSort(Asc/Desc)ending()
    property is defined
*/
const SortableTable = (props: SortableTableProps) => {
  const { header, data, initialSortDetails, onSortCallback } = props;
  const [sort, setSort] = useState<SortByIndex>(
    getInitialSort(header, initialSortDetails)
  );
  const isFirstLoad = useRef(true);
  function handleClick(
    headerItem: SortableHeaderItemData,
    indexOfClickedHeader: number
  ) {
    setSort((currSort) => {
      const newSort = getNewSortDetails(
        indexOfClickedHeader,
        currSort,
        headerItem
      );
      if (sortsAreEqual(currSort, newSort)) {
        return currSort; // avoid recalling onSort callback if sort doesn't change (e.g. reclick same button with a single sort)
      }
      return newSort;
    });
  }
  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
      return;
    } // prevent re-sort on initial load
    const keyOfClicked = header[sort.indexOfSortedHeader]?.dataKey;
    if (keyOfClicked === undefined) return;
    onSortCallback(sort.sortDirection, keyOfClicked);
  }, [sort]);

  const headerItems = createHeaderItems(
    header,
    sort,
    handleClick,
    props.stickyTopPosition
  );
  const bodyContents = createBodyContents(data, header);
  return (
    <StaticTable
      header={headerItems}
      data={bodyContents}
      shouldSkipHeaderItemConstruction={true}
    />
  );
};

function sortDirectionIsAllowed(
  sortDirection: SortDirection,
  allowedSortDirections: AllowedSortDirections
) {
  switch (sortDirection) {
    case SortDirection.ASCENDING:
      return [
        AllowedSortDirections.ASCENDING_ONLY,
        AllowedSortDirections.BOTH,
      ].includes(allowedSortDirections);
    case SortDirection.DESCENDING:
      return [
        AllowedSortDirections.DECENDING_ONLY,
        AllowedSortDirections.BOTH,
      ].includes(allowedSortDirections);
    default:
      return false;
  }
}

function canSortAscending(allowedSortDirections: AllowedSortDirections) {
  return sortDirectionIsAllowed(SortDirection.ASCENDING, allowedSortDirections);
}

function canSortDescending(allowedSortDirections: AllowedSortDirections) {
  return sortDirectionIsAllowed(
    SortDirection.DESCENDING,
    allowedSortDirections
  );
}

function getInitialSort(
  header: SortableHeaderItemData[],
  initialSortDetails: InitialSortDetails
): SortByIndex {
  if (initialSortDetails === SortDirection.NONE) {
    return {
      sortDirection: SortDirection.NONE,
      indexOfSortedHeader: -1,
    };
  }
  for (var i = 0; i < header.length; i++) {
    const headerData = header[i];
    if (headerData === undefined) {
      throw new Error("Header not found");
    }
    const { dataKey, allowedSortDirections, title } = headerData;
    if (initialSortDetails.keyOfSortedColumn === dataKey) {
      if (
        sortDirectionIsAllowed(
          initialSortDetails.sortDirection,
          allowedSortDirections
        )
      ) {
        return {
          sortDirection: initialSortDetails.sortDirection,
          indexOfSortedHeader: i,
        };
      } else {
        throw new Error(
          `Initial sort "${initialSortDetails.sortDirection}" is not allowed for "${title}" {key: "${dataKey}"}. Allowed sort directions = ${allowedSortDirections}`
        );
      }
    }
  }
  throw new Error(
    `Initial sort key "${
      initialSortDetails.keyOfSortedColumn
    }" does not match any of the header keys [${header.map(
      ({ dataKey }) => dataKey
    )}]`
  );
}

function getSortDirectionOfItem(itemIndex: number, currSort: SortByIndex) {
  if (itemIndex === currSort.indexOfSortedHeader) {
    return currSort.sortDirection;
  } else {
    return SortDirection.NONE;
  }
}

function getNewSortOnClick(
  currSort: SortDirection,
  headerItem: SortableHeaderItemData
) {
  const { allowedSortDirections } = headerItem;
  if (currSort === SortDirection.NONE) {
    // on initial click, switch to descending if possible
    if (canSortDescending(allowedSortDirections)) {
      return SortDirection.DESCENDING;
    }
    // if it can only be sorted in ascending, switch to ascending on first click
    else if (canSortAscending(allowedSortDirections)) {
      return SortDirection.ASCENDING;
    }
  } else if (currSort === SortDirection.ASCENDING) {
    if (canSortDescending(allowedSortDirections)) {
      return SortDirection.DESCENDING;
    }
  } else if (currSort === SortDirection.DESCENDING) {
    if (canSortAscending(allowedSortDirections)) {
      return SortDirection.ASCENDING;
    }
  }
  return currSort;
}

function createHeaderItems(
  header: SortableHeaderItemData[],
  sort: SortByIndex,
  handleClick: Function,
  stickyTopPosition?: string
) {
  return header.map((headerItem, i) => {
    const isFirstCol = i === 0;
    const isLastCol = i === header.length - 1;
    if (headerItem.allowedSortDirections === AllowedSortDirections.NEITHER) {
      return (
        <StaticHeaderItem
          key={i}
          content={headerItem.title}
          isFirstCol={isFirstCol}
          isLastCol={isLastCol}
          stickyTopPosition={stickyTopPosition}
        />
      );
    } else {
      const itemSort = getSortDirectionOfItem(i, sort);
      return (
        <SortableHeaderItem
          key={i}
          onClick={() => handleClick(headerItem, i)}
          sortDirection={itemSort}
          title={headerItem.title}
          allowedSortDirection={headerItem.allowedSortDirections}
          isFirstCol={isFirstCol}
          isLastCol={isLastCol}
          stickyTopPosition={stickyTopPosition}
        />
      );
    }
  });
}

function getNewSortDetails(
  indexOfClickedHeader: number,
  currSort: SortByIndex,
  headerItem: SortableHeaderItemData
): SortByIndex {
  const sortDirectionOfClicked = getSortDirectionOfItem(
    indexOfClickedHeader,
    currSort
  );
  const newSortDirection = getNewSortOnClick(
    sortDirectionOfClicked,
    headerItem
  );
  return {
    sortDirection: newSortDirection,
    indexOfSortedHeader: indexOfClickedHeader,
  };
}

function sortsAreEqual(a: SortByIndex, b: SortByIndex) {
  return (
    a.sortDirection === b.sortDirection &&
    a.indexOfSortedHeader === b.indexOfSortedHeader
  );
}

function createBodyContents(
  data: TableBodyData,
  header: SortableHeaderItemData[]
): TableCellContent[][] {
  // order data into columns based on the header keys
  return data.map((row) => {
    return header.map(({ dataKey }) => {
      return dataKey in row ? row[dataKey] : IsLoading.true;
    });
  });
}

interface SortByIndex {
  sortDirection: SortDirection;
  indexOfSortedHeader: number;
}

export default SortableTable;
