import styles from "./Scaffold.module.css";
import Header from "components/Header";
import SideMenu from "components/SideMenu";
import { ReactNode } from "react";
import { useShopInfo } from "components/Scaffold/useShopInfo";
import { useCurrentUser } from "core/firebase/hooks/useCurrentUser";

const Scaffold = ({ children }: ScaffoldProps) => {
  const { shopInfo, hasError: shopInfoHasError } = useShopInfo();
  const currentUser = useCurrentUser();

  return (
    <>
      <Header
        hasError={shopInfoHasError}
        name={shopInfo?.shopOwner ? shopInfo.shopOwner : null}
        storeUrl={currentUser?.uid ? currentUser.uid : null}
      />
      <div className={styles.contentPositioning}>
        <div className={styles.content}>{children}</div>
      </div>
      <div className={styles.sideMenuPositioning}>
        <SideMenu />
      </div>
    </>
  );
};

export interface ScaffoldProps {
  children: ReactNode;
}

export default Scaffold;
