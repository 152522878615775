import styles from "./Table.module.css";
import { StaticTableProps } from "./types";
import { StaticHeaderItem } from "./components/HeaderItem";
import TableCell from "./components/TableCell";
import React from "react";

const StaticTable = (props: StaticTableProps) => {
  const { header, data, shouldSkipHeaderItemConstruction } = props;

  const headerElements = getHeaderElements(
    header,
    shouldSkipHeaderItemConstruction
  );
  const rowElements = getRowElements(data);

  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.header}>{headerElements}</tr>
        </thead>
        <tbody className={styles.body}>{rowElements}</tbody>
      </table>
    </div>
  );
};

function getHeaderElements(
  header: React.ReactNode[],
  shouldSkipHeaderItemConstruction: boolean | undefined
) {
  if (shouldSkipHeaderItemConstruction) {
    return header;
  }
  return createStaticHeaderItems(header);
}

function createStaticHeaderItems(
  headerContents: React.ReactNode[]
): React.ReactNode[] {
  return headerContents.map((content, i) => {
    return (
      <StaticHeaderItem
        content={content}
        isFirstCol={i === 0}
        isLastCol={i === headerContents.length - 1}
        key={i}
      />
    );
  });
}

function getCellsInRow(contentsInCells: React.ReactNode[], rowIndex: number) {
  return contentsInCells.map((contents, colIndex) => {
    return (
      <TableCell
        content={contents}
        colIndex={colIndex}
        key={`${rowIndex},${colIndex}`}
      />
    );
  });
}

function getRowElements(data: React.ReactNode[][]): React.ReactNode[] {
  return data.map((rowContents, rowIndex) => {
    const isLastRow = rowIndex === data.length - 1;
    const rowElements = getCellsInRow(rowContents, rowIndex);
    return (
      <tr
        className={isLastRow ? styles.lastRow : styles.row}
        key={"row " + rowIndex}
      >
        {rowElements}
      </tr>
    );
  });
}

export default StaticTable;
