import styles from "../../Table.module.css";
import {
  AllowedSortDirections,
  SortableHeaderItemProps,
  SortDirection,
  StaticHeaderItemProps,
} from "../../types";
import {
  SortingIconBothAscending,
  SortingIconBothDescending,
  SortingIconBothInactive,
  SortingIconOnlyDownDescending,
  SortingIconOnlyDownInactive,
  SortingIconOnlyUpAscending,
  SortingIconOnlyUpInactive,
} from "./sortIcons";

const StaticHeaderItem = (props: StaticHeaderItemProps) => {
  const { content, isFirstCol, isLastCol } = props;
  const classNames = getClassNames(isFirstCol, isLastCol, false);
  return (
    <th
      className={classNames}
      style={
        props.stickyTopPosition
          ? { position: "sticky", top: props.stickyTopPosition }
          : {}
      }
    >
      {content}
    </th>
  );
};

const SortableHeaderItem = (props: SortableHeaderItemProps) => {
  const {
    title,
    sortDirection,
    allowedSortDirection,
    isFirstCol,
    isLastCol,
    onClick,
  } = props;

  const classNames = getClassNames(isFirstCol, isLastCol, true);
  const sortIcon = getSortIcon(sortDirection, allowedSortDirection);
  return (
    <th
      className={classNames}
      style={
        props.stickyTopPosition
          ? { position: "sticky", top: props.stickyTopPosition }
          : {}
      }
      onClick={onClick}
    >
      <div className={styles.sortableHeaderItemInner}>
        {title}
        {sortIcon}
      </div>
    </th>
  );
};

export function getSortIcon(
  sort: SortDirection,
  allowedSortDirection: AllowedSortDirections
) {
  switch (sort) {
    case SortDirection.NONE:
      if (allowedSortDirection === AllowedSortDirections.BOTH) {
        return <SortingIconBothInactive />;
      } else if (
        allowedSortDirection === AllowedSortDirections.ASCENDING_ONLY
      ) {
        return <SortingIconOnlyUpInactive />;
      } else if (
        allowedSortDirection === AllowedSortDirections.DECENDING_ONLY
      ) {
        return <SortingIconOnlyDownInactive />;
      }
      break;
    case SortDirection.DESCENDING:
      if (allowedSortDirection === AllowedSortDirections.BOTH) {
        return <SortingIconBothDescending />;
      } else if (
        allowedSortDirection === AllowedSortDirections.DECENDING_ONLY
      ) {
        return <SortingIconOnlyDownDescending />;
      }
      break;
    case SortDirection.ASCENDING:
      if (allowedSortDirection === AllowedSortDirections.BOTH) {
        return <SortingIconBothAscending />;
      } else if (
        allowedSortDirection === AllowedSortDirections.ASCENDING_ONLY
      ) {
        return <SortingIconOnlyUpAscending />;
      }
      break;
  }
}

function getClassNames(
  isFirstCol: boolean,
  isLastCol: boolean,
  isSortable: boolean
) {
  const styleClasses = [styles.headerItem];
  if (isFirstCol) {
    styleClasses.push(styles.firstCol);
  }
  if (isLastCol) {
    styleClasses.push(styles.lastCol);
  }
  if (isSortable) {
    styleClasses.push(styles.sortableHeaderItem);
  }
  return styleClasses.join(" ");
}

export { SortableHeaderItem, StaticHeaderItem };
