import styles from "./MenuItem.module.css";
import { Icon, IconSource } from "@shopify/polaris";
import { MouseEventHandler, useRef, useState } from "react";

const MenuItem = ({ title, icon, isSelected, onClick }: MenuItemProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const menuItemRef = useRef<HTMLDivElement>(null);

  return (
    <div className={styles.menuItem}>
      {showTooltip ? <MenuTooltip title={title} /> : null}
      <div
        className={styles.selectionIndicator}
        style={{ visibility: isSelected ? "visible" : "hidden" }}
      />
      <div
        onClick={onClick}
        className={[
          styles.menuItemContent,
          isSelected ? styles.selectedBackground : "",
        ].join(" ")}
        onMouseEnter={() => {
          setShowTooltip(true);
        }}
        onMouseLeave={() => {
          setShowTooltip(false);
        }}
        ref={menuItemRef}
      >
        <div
          className={[
            styles.menuItemIcon,
            isSelected ? styles.selectedIcon : "",
          ].join(" ")}
        >
          <Icon source={icon} />
        </div>
        <span
          className={[
            styles.menuItemText,
            isSelected ? styles.selectedText : "",
          ].join(" ")}
        >
          {title}
        </span>
      </div>
    </div>
  );
};

const MenuTooltip = ({ title }: { title: string }) => {
  return <div className={styles.tooltip}>{title}</div>;
};

export interface MenuItemProps {
  title: string;
  icon: IconSource;
  isSelected: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
}

export default MenuItem;
