import styles from "../Chart.module.css";

export const DateRangeDropdown = (props: DateRangeDropdownProps) => {
  const options = [
    DateRangeSelections.custom,
    DateRangeSelections.today,
    DateRangeSelections.yesterday,
    DateRangeSelections.last7days,
    DateRangeSelections.last30days,
    DateRangeSelections.prevMonth,
    DateRangeSelections.prevYear,
    DateRangeSelections.weekToDate,
    DateRangeSelections.monthToDate,
    DateRangeSelections.yearToDate,
    DateRangeSelections.quarter4lastYear,
    DateRangeSelections.quarter3lastYear,
    DateRangeSelections.quarter2lastYear,
    DateRangeSelections.quarter1lastYear,
  ];
  return (
    <select
      className={styles.dateRangeDropdown}
      onChange={props.onChange}
      value={props.value}
    >
      ;
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export const DateRangeSelections = {
  custom: "Custom",
  today: "Today",
  yesterday: "Yesterday",
  last7days: "Last 7 Days",
  last30days: "Last 30 Days",
  prevMonth: "Previous month",
  prevYear: "Previous year",
  weekToDate: "Week to Date",
  monthToDate: "Month to Date",
  yearToDate: "Year to Date",
  quarter4lastYear: `4th Quarter ${new Date().getFullYear() - 1}`,
  quarter3lastYear: `3rd Quarter ${new Date().getFullYear() - 1}`,
  quarter2lastYear: `2nd Quarter ${new Date().getFullYear() - 1}`,
  quarter1lastYear: `1st Quarter ${new Date().getFullYear() - 1}`,
};

interface DateRangeDropdownProps {
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string;
}
